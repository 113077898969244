import { Injectable } from '@angular/core';
import { AuthHttpService } from './auth-http.service';
import { EventEmitterService } from './event-emitter.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PermissionDataService } from 'src/app/service/permission-data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupNotificationComponent } from '../templates/authorized/popup-notification/popup-notification.component';
import { CommunicationService } from 'src/app/service/communication.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AttendenceService {
  attendenceDefault: boolean = false;
  allAttendenceData = [];
  finalData = [];
  userStr = [];
  constructor(
    private authHttp: AuthHttpService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private permissionService: PermissionDataService,
    private dialog: MatDialog,
    private comm: CommunicationService
  ) {}

  fetchAttendenceForOne(uids) {
    let data: any = {
      user_ids: uids,
      limit: 50,
      offset: 0,
    };
    this.authHttp.getUserAttendence(data).subscribe((response) => {
      let latlonArray = [];
      if (response.data[0].latest_punch_type == 'OUT') {
        latlonArray = response.data[0]?.latest_check_out_latlong?.split(',');
      } else {
        latlonArray = response.data[0]?.latest_check_in_latlong?.split(',');
      }
	  
	  if(latlonArray?.length >0) {
      localStorage.setItem('lat_in', latlonArray[0]);
      localStorage.setItem('lon_in', latlonArray[1]);
	  }
      localStorage.setItem(
        'single_user_login_time',
        response.data[0].latest_punch_time
      );

      localStorage.setItem(
        'latest_punch_type',
        response.data[0].latest_punch_type
      );
    });
  }
  
	fetchAllUsersFirstCheckIn() {
    let data: any = {};
    this.authHttp.getAllUsersFirstCheckIn(data).subscribe((response) => {      
      localStorage.setItem(
        'allUsersFirstCheckIn',
        JSON.stringify(response.data)
      );
	this.comm.loadUpdatedCheckInOut$.next('true');
      
    });
  }
  
  fetchAttendenceForMultiple(uids) {
    let allUids = [];
    allUids.push(uids);
    let data: any = {
      user_ids: uids,
      limit: 50,
      offset: 0,
    };
    this.authHttp.getUserAttendence(data).subscribe((response) => {
      this.allAttendenceData = response.data;
      this.allAttendenceData.forEach((object) => {
        object.attendenceDefaultedClass = this.attenedenceDefaulted(
          object.latest_punch_time,
          object.latest_punch_type
        );
        this.finalData[object.id] = object;
      });
    });
  }

  async processAllAttendenceData(uids) {
    this.fetchAttendenceForMultiple(uids);
    return this.finalData;
  }

  attenedenceDefaulted(timestamp, latest_punch_type, userId:any=null) {
	latest_punch_type = latest_punch_type == 'null'? null : latest_punch_type;
	const firstCheckInUsers = JSON.parse(localStorage.getItem('allUsersFirstCheckIn'));
    
	if(userId != null && firstCheckInUsers !=null) {
	 let member = firstCheckInUsers.find((member) => member.userid === userId);
	 timestamp = member?.first_check_in?member?.first_check_in:null;
	}
    const attendenceDate = moment.utc(timestamp).local()
     .format('YYYY-MM-DD');
	 
    const attendenceTime = moment.utc(timestamp).local()
     .format('YYYY-MM-DD HH:mm:ss');

    const currentDate_utc = moment.utc().format('YYYY-MM-DD');
    const currentDate_local = moment().local().format('YYYY-MM-DD');
    const currentTime_local = moment().local().format('YYYY-MM-DD HH:mm:ss');

    let cls: any;
	// commented for new logic
    //let inOut = localStorage.getItem('IS_AUTO_RESET_OUT_TO_IN');
	let inOut = 'N';
    //let diffDate = moment(currentDate_local).diff(moment(attendenceDate), 'days');
    let diffHours = moment.duration(
      moment(currentTime_local).diff(moment(attendenceTime))
    );
    let totalMinutes = Number(diffHours.asMinutes());
    localStorage.setItem('current_colleauge_is_red', 'false');
	
	if(attendenceDate == '0001-01-01' || attendenceDate == null)
	latest_punch_type = null;
	
    if (latest_punch_type != null) {

      if (attendenceDate != currentDate_local) {
        if ( inOut == 'Y' && totalMinutes <= 1020 ) { 
          cls = 'border-green';
        } else {
          cls = 'border-red';
          localStorage.setItem('current_colleauge_is_red', 'true');
        }
        if (latest_punch_type == 'OUT') {
          cls = 'border-gray-n-3';
        }
      } else {
        if ( inOut == 'Y' && totalMinutes <= 1020 ) { 
          cls = 'border-green';
        } else if ((inOut == 'N' || inOut == 'null') && latest_punch_type == 'IN') {
          cls = 'border-green';
        } else {
          cls = 'border-red';
          localStorage.setItem('current_colleauge_is_red', 'true');
        }
		
        if (latest_punch_type == 'OUT') {
          cls = 'border-gray-n-3';
        }
      }
    } else {
      cls = 'team-border-0';
    }

    return cls;
  }

  setAllUusersObj() {
    // const all_users = JSON.parse(localStorage.getItem('all_users'));
    // all_users.forEach((object) => {
    //   this.userStr.push(object.id);
    // });
    // this.processAllAttendenceData(this.userStr);
  }
}
